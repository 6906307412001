import { CmpModuleSettings, CommonSettings, FeatureSettings, LayoutSettings, ModuleObject, NetIdModuleSettings, PositionSettings, UtiqModuleSettings, YieldloveModuleSettings } from '@mbrtargeting/metatag-config-types';
import { Config } from '../../interfaces/interfaces.js';
import { simpleStorageSwitch, simpleUrlSwitch } from '../../utils/devops-options-helper.js';
import { injectionTarget } from '../decorators/inject.js';

@injectionTarget()
export class ConfigResolver {

    constructor(
        private config: Config,
    ) {
        this.evaluateSettingSwitches();
    }

    /**
     * searches for markers in cookies/localStorage/sessionStorage to determine if we need to change any config settings on the fly
     */
    private evaluateSettingSwitches() {
        // sdgCmp=tcf2 or sdgCmp=exttcf2 or sdgCmp=off in url or storage
        const localStorageCmpParam = simpleStorageSwitch('sdgCmp', /^(tcf2|exttcf2|off)$/);
        const urlCmpParam = simpleUrlSwitch(/sdgCmp=(tcf2|exttcf2|off)/i);
        const consentConfig: Partial<CmpModuleSettings> = this.getModuleSettings('CONSENTMANAGER') || {};
        if (urlCmpParam === 'tcf2' || localStorageCmpParam === 'tcf2') {
            update(consentConfig, {
                active: true,
                externalCMP: false,
                nonIABCMP: false,
            });
        }
        if (urlCmpParam === 'exttcf2' || localStorageCmpParam === 'exttcf2') {
            update(consentConfig, {
                active: true,
                externalCMP: true,
                nonIABCMP: false,
            });
        }
        if (urlCmpParam === 'off' || localStorageCmpParam === 'off') {
            update(consentConfig, {
                active: false,
            });
        }

        // sdgClsDemo=1 in url or storage
        if (simpleUrlSwitch(/sdgClsDemo=(1|true)/i) || simpleStorageSwitch('sdgClsDemo', /^(1)$/)) {
            update(this.config.layout, {
                reduceLayoutShift: true,
                centerAds: true,
            });
        }

        // google - disable mcm for not-whitelistet test systems
        const sdgMcmIdParam = simpleUrlSwitch(/sdgMcmId=([0-9]+)/i) || simpleStorageSwitch('sdgMcmId', /^([0-9]+)$/);
        if (sdgMcmIdParam) {
            const mcmId = /^0+$/.test(sdgMcmIdParam) ? '' : sdgMcmIdParam;
            const { common, adserver } = this.config;
            update(common, { dfpChildID: mcmId });
            update(adserver?.dfp?.config, { mcmId });
            update(adserver?.ringier?.config, { mcmId });
        }

        // yieldlove
        const ylSettings: Partial<YieldloveModuleSettings> = this.getModuleSettings('PREBID') || {};
        const ylActiveParam = simpleUrlSwitch(/ylactive=(0|1|true|false)/i)?.toLowerCase();
        const ylDomainParam = simpleUrlSwitch(/yldomain=([a-z0-9_.\-]+)/i);
        const ylVersionParam = simpleUrlSwitch(/ylversion=([0-9a-z_\-.]+)/i);
        const ylLayoutParam = simpleUrlSwitch(/yllayout=([a-z0-9_.\-]+)/i);
        update(ylSettings, {
            active: ylActiveParam ? ['1', 'true'].includes(ylActiveParam) : ylSettings.active,
            yieldloveDomainName: ylDomainParam ?? ylSettings.yieldloveDomainName,
            yieldloveVersion: ylVersionParam ?? ylSettings.yieldloveVersion,
            staticAdLayout: ylLayoutParam ?? ylSettings.staticAdLayout,
        });

        // netid
        const netIdSettings: Partial<NetIdModuleSettings> = this.getModuleSettings('NETID') ?? {};
        const netidActiveParam = simpleUrlSwitch(/sdgNetId=(0|1|true|false)/) || simpleStorageSwitch('sdgNetId', /^(0|1|true|false)$/);
        const netidDelayLayerTimeParam = simpleUrlSwitch(/sdgNetIdDelay=([0-9]+)/i) || simpleStorageSwitch('sdgNetIdDelay', /^([0-9]+)$/);
        update(netIdSettings, {
            active: netidActiveParam ? ['1', 'true'].includes(netidActiveParam) : netIdSettings.active,
            delayLayerTime: netidDelayLayerTimeParam ? +netidDelayLayerTimeParam : netIdSettings.delayLayerTime,
        });

        // utiq
        const utiqSettings: Partial<UtiqModuleSettings> = this.getModuleSettings('UTIQ') ?? {};
        const utiqActiveParam = simpleUrlSwitch(/sdgUtiq=(0|1|true|false)/) || simpleStorageSwitch('sdgUtiq', /^(0|1|true|false)$/);
        const utiqDelayLayerTimeParam = simpleUrlSwitch(/sdgUtiqDelay=([0-9]+)/) || simpleStorageSwitch('sdgUtiqDelay', /^([0-9]+)$/);
        update(utiqSettings, {
            active: utiqActiveParam ? ['1', 'true'].includes(utiqActiveParam) : utiqSettings.active,
            delayLayerTime: utiqDelayLayerTimeParam ? +utiqDelayLayerTimeParam : utiqSettings.delayLayerTime,
        });
    }

    public getConfig(): Config {
        return this.config;
    }

    public getCommonSettings(): CommonSettings {
        return this.config.common;
    }

    public getLayoutSettings(): LayoutSettings {
        return this.config.layout;
    }

    public getFeatureSettings(): FeatureSettings {
        return this.config.features;
    }

    public getTemplate(templateName: string): string | undefined {
        return this.config.templates[templateName];
    }

    public getPositionSettings(slotName: string): PositionSettings | undefined {
        return this.config.positions[slotName];
    }

    public getModuleSettings<ModuleName extends keyof ModuleObject>(moduleName: ModuleName): ModuleObject[ModuleName] | undefined {
        return this.config.modules.map(module => module[moduleName]).find(config => config);
    }
}

/**
 * Partially updates the target object with properties from source object.
 *
 * A wrapper to Object.assign with type constraints.
 * Copy the values of all of the enumerable own properties from one or more source objects to a target object. Returns the target object.
 *
 * @param target The target object to copy to.
 * @param source The source object from which to copy properties.
 * @returns the target object
 */
const update = <T extends object>(target: T, source: Partial<T>) => Object.assign<T, Partial<T>>(target ?? {}, source);
