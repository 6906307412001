import { createCmpStub } from '@mbrtargeting/metatag-cmp-stub';
import { TCFAPI } from '@mbrtargeting/metatag-cmp-types';
import { SystemEvent } from '@mbrtargeting/metatag-shared-types/metatag-core';
import { FetchPriority, isFunction, loadScript } from '@mbrtargeting/metatag-utils';
import { inject, injectionTarget } from '../decorators/inject.js';
import { onSystemEvent } from '../decorators/on-system-event.js';
import { ConfigResolver } from '../essentials/config-resolver.js';
import { logger } from '../essentials/logger.js';

const log = logger.logGroup({ prefix: 'SourcepointLoader' });

declare var window: Window & TCFAPI & {
    _sp_?: sourcepoint.SourcePoint;
};

@injectionTarget()
export class SourcepointLoader {

    @inject() #configResolver!: ConfigResolver;

    @onSystemEvent(SystemEvent.SDG_CONFIG_FILE_AVAILABLE)
    init(): void {
        const {
            active,
            externalCMP,
            spAccountId,
            spBaseEndpoint,
            spControllerFile,
            activateURIEncoding,
        } = this.#configResolver.getModuleSettings('CONSENTMANAGER') ?? {};

        // if consentManager module is not activated, we do not need to do anything
        if (!active) return;
        // if publisher uses own cmp, we do not need to load sourcePoint
        if (externalCMP) return;

        // configure sourcepoint
        window._sp_ = {
            config: {
                accountId: spAccountId!,
                baseEndpoint: spBaseEndpoint!,
                gdpr: {
                    targetingParams: {}
                },
                events: {
                    onMessageChoiceError: (messageType, err) => {
                        log.debug('CMP reports an error: %o.', [messageType, err]);
                    },
                },
                ...(activateURIEncoding) && {
                    propertyHref: encodeURI(window.location.href),
                },
                ...(['localhost', 'bs-local.com'].includes(window.location.hostname)) && {
                    propertyHref: 'https://stroeerdev.de',
                }
            }
        };

        // define window.__tcfapi with cmp stub
        if (!isFunction(window.__tcfapi)) {
            createCmpStub();
        }

        // load sourcePoint as cmp implementation
        loadScript({
            src: `${spBaseEndpoint}/${spControllerFile}`,
            async: true,
            node: document.head,
            fetchpriority: FetchPriority.HIGH,
            onload: () => log.notice('script loaded'),
            onerror: (event) => log.critical('script could not be loaded', [event]),
        });
    }
}
