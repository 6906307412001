import { logEntries$, LogEntry, logGroup, LogLevel } from '@mbrtargeting/metatag-shared-modules';
import { cssText } from '@mbrtargeting/metatag-utils';
import { simpleStorageSwitch } from '../../utils/devops-options-helper.js';

const logger = logGroup({ system: 'MetaTag', prefix: '' });

export { logger as log, logEntries$, logger };

class ColorfulConsole {

    #styles: {
        identStyle: string;
        levelStyles: Record<LogLevel, string>;
        metaStyle: string;
        messageStyle: string;
    };

    constructor() {
        // some shared styling
        const baseCss: Partial<CSSStyleDeclaration> = { display: 'inline-block', marginRight: '2px', padding: '2px 4px', borderRadius: '5px' };

        // prepare all styles to make logging faster
        this.#styles = {
            identStyle: cssText({ ...baseCss, color: '#fff', backgroundColor: '#005276' }),
            levelStyles: {
                [LogLevel.DEBUG]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#2e8b57' }),
                [LogLevel.INFO]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#6b5b95' }),
                [LogLevel.NOTICE]: cssText({ ...baseCss, backgroundColor: '#b2ad7f' }),
                [LogLevel.DEPRECATED]: cssText({ ...baseCss, backgroundColor: '#f84df8' }),
                [LogLevel.WARNING]: cssText({ ...baseCss, backgroundColor: '#ffbcdf' }),
                [LogLevel.ERROR]: cssText({ ...baseCss, backgroundColor: '#ff859d' }),
                [LogLevel.CRITICAL]: cssText({ ...baseCss, backgroundColor: '#fd4d59' }),
                [LogLevel.ALERT]: cssText({ ...baseCss, backgroundColor: '#c30053' }),
                [LogLevel.EMERGENCY]: cssText({ ...baseCss, color: '#fff', backgroundColor: '#cf000f' }),
            },
            metaStyle: cssText({ ...baseCss, color: '#aaa', backgroundColor: '#fff' }),
            messageStyle: '',
        };
    }

    public sendLogToConsole({ logLevel, message, messageObjects = [], system = '' }: LogEntry): void {
        const { identStyle, levelStyles, metaStyle, messageStyle } = this.#styles;
        window.console?.log?.apply(this, [`%cSDG%c${LogLevel[logLevel].padStart(10)}%c${system.padEnd(10)}%c ${message}`, identStyle, levelStyles[logLevel], metaStyle, messageStyle, ...messageObjects]);
    }
}

// creating a logSink with css styling - assuming all modern browsers support this as we have dropped support for IE
const logSink = new ColorfulConsole();

// reading the wanted console log level from storage entry
const consoleLogLevel = parseInt(simpleStorageSwitch('sdgDumpLogsToConsole', /^([0-9]+)$/) || '') || LogLevel.ERROR;

// send the logs to the browser console
logEntries$
    .filter(entry => entry.logLevel >= consoleLogLevel)
    .subscribe(entry => logSink.sendLogToConsole(entry));
